(function() {
    // init
    var elemStore = new Map();

    // IMG PRELOAD
    let imgs = [
        "balloon_blue",
        "balloon_red",
        "balloon_yellow",
        "bush_0",
        "bush_1",
        "bush_2",
        "bush_3",
        "cloud_0",
        "cloud_1",
        "cloud_2",
        "hills",
        "sun",
        "logo"
    ];

    const loadImage = id =>
        new Promise(resolve => {
            const img = new Image();
            const url = `/images/${id}.webp`;
            img.onload = () => resolve({ url, id, ok: true });
            img.onerror = () => resolve({ url, id, ok: false });
            img.src = url;
        });

    Promise.all(imgs.map(id => loadImage(id))).then(e => {
        e.forEach(item => {
            setDivBackground(item.url, item.id);
        });
        stopLoading();
    });

    function setDivBackground(url, id) {
        elementById(id).style.backgroundImage = `url("${url}")`;
    }

    function stopLoading() {
        elementById("loader").style.display = "none";
        imgs = [];
    }

    // PARALAX
    var header = elementById("header");
    var headerHeight = header.getBoundingClientRect().height;

    window.document.onscroll = _ => {
        var scrollY = window.scrollY;
        //fix pull down on phones
        if (scrollY < 0) {
            scrollY = 0;
        }
        //HEADER
        if (scrollY <= headerHeight) {
            adjustBottom(
                scrollY,
                ["cloud_0", "cloud_1", "cloud_2", "sun", "logo"],
                -0.32
            );
            adjustBottom(scrollY, ["hills"], -0.2);
            adjustBottom(
                scrollY,
                ["balloon_red", "balloon_blue", "balloon_yellow"],
                -0.15
            );
            adjustBottom(
                scrollY,
                ["bush_0", "bush_1", "bush_2", "bush_3"],
                -0.1
            );
        }
    };

    function adjustBottom(scrollY, elemIds, speed) {
        const offset = Math.round(speed * scrollY);
        elemIds.forEach(id => {
            elementById(id).style.bottom = `${offset}px`;
        });
    }

    function elementById(elementId) {
        if (elemStore.has(elementId)) {
            return elemStore.get(elementId);
        }
        let element = document.getElementById(elementId);
        elemStore.set(elementId, element);
        return element;
    }
})();
