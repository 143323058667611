"use strict";

(function() {
    const paths = new Map();
    const canvas = document.getElementById("clouds_canvas");
    const parent = canvas.parentElement;
    // size: 100 x 71,8 pt
    const cloudPath = new Path2D(
        "M46.876,62.757l0.284,0.448c3.388,5.357 9.182,8.555 15.498,8.555c0.772,0 1.553,-0.05 2.324,-0.147c7.424,-0.942 13.478,-6.222 15.424,-13.451l0.073,-0.272l0.28,0.034c1.405,0.171 2.836,0.168 4.234,-0.01c9.388,-1.189 16.058,-9.794 14.87,-19.182c-0.732,-5.785 -4.308,-10.756 -9.567,-13.298l-0.187,-0.091l0.004,-0.207c0.012,-0.812 -0.031,-1.615 -0.129,-2.386c-1.049,-8.291 -8.133,-14.543 -16.478,-14.543c-0.7,0 -1.41,0.045 -2.109,0.133c-2.155,0.271 -4.209,0.952 -6.103,2.025l-0.276,0.156l-0.164,-0.272c-3.786,-6.322 -10.699,-10.249 -18.041,-10.249c-0.888,0 -1.787,0.057 -2.674,0.17c-9.236,1.169 -16.513,8.111 -18.107,17.276l-0.062,0.355l-0.346,-0.098c-2.571,-0.725 -5.289,-0.925 -7.982,-0.587c-11.035,1.399 -18.877,11.514 -17.481,22.548c1.273,10.054 9.867,17.636 19.991,17.636l0.511,-0.004l0.053,0.572c0.883,6.958 6.839,12.212 13.852,12.212c0.586,0 1.179,-0.037 1.765,-0.111c4.306,-0.546 8.051,-3.008 10.272,-6.756l0.271,-0.456Z"
    );

    window.onresize = _event => {
        draw(canvas, parent.clientWidth, parent.clientHeight);
    };

    function main() {
        draw(canvas, parent.clientWidth, parent.clientHeight);
    }

    main();

    function draw(canvas, clientWidth, clientHeight) {
        updateCanvasSize(canvas, clientWidth, clientHeight);
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, clientWidth, clientHeight);
        let { offsetX, offsetY, ratio } = calcLargestCloud(canvas);
        drawDeepCloud(ctx, offsetX, offsetY, ratio);

        //white clouds:
        const cloudsData = [
            { x: 5, y: 22, w: 20.4, h: 14.7, logoPath: "ltm" }, //small @ mid-left
            { x: 59.7, y: 58, w: 15.5, h: 11.1, logoPath: "npg" }, //small @ bottom right
            { x: 22, y: 58, w: 15.5, h: 11.1, logoPath: "theimagegroup" }, //small @ bottom left
            { x: 6, y: 33, w: 38, h: 30, logoPath: "disneycl" }, //medium @ left
            { x: 8, y: 6, w: 23.5, h: 18.5, logoPath: "mol" }, //medium @ top-right
            { x: 72, y: 8, w: 23.5, h: 18.5, logoPath: "tate" }, //medium @ top-right
            { x: 54.1, y: 45, w: 20.4, h: 14.7, logoPath: "cunard" }, //small @ mid-bottom right
            { x: 65, y: 30, w: 34, h: 32, logoPath: "nat" }, //medium @ right
            { x: 36, y: 52, w: 23.5, h: 18.5, logoPath: "royal" }, //small @ bottom center
            { x: 22, y: 3, w: 59.6, h: 42.8, logoPath: "vam" } //largest @ top
        ];

        // fetch all data in parallel
        cloudsData.reduce(function(sequence, { x, y, w, h, logoPath }) {
            return sequence
                .then(function() {
                    return loadPath(logoPath);
                })
                .then(function(path) {
                    drawWhiteCloud(
                        ctx,
                        x * ratio + offsetX,
                        y * ratio + offsetY,
                        w * ratio,
                        h * ratio,
                        path
                    );
                });
        }, Promise.resolve());
    }

    function updateCanvasSize(canvas, width, height) {
        canvas.width = width;
        canvas.height = height;
    }

    function calcLargestCloud(canvas) {
        const margin = 24;
        let { width, height, ratio } = fitCloudInto(
            canvas.width - margin,
            canvas.height - margin
        );
        let offsetX = (canvas.width - width) >> 1;
        let offsetY = (canvas.height - height) >> 1;
        return { offsetX, offsetY, ratio };
    }

    function drawDeepCloud(ctx, x, y, ratio) {
        //draw shadow, render way off-screen
        ctx.save();
        const shadowOffScreen = 2000;
        ctx.setTransform(ratio, 0, 0, ratio, x - shadowOffScreen, y);
        //bring only the shadow back to screen with offsetX
        ctx.lineWidth = 4;
        const shadowOffSet = 1 * ratio;
        ctx.shadowOffsetX = shadowOffScreen + shadowOffSet;
        ctx.shadowOffsetY = shadowOffSet;
        ctx.shadowBlur = 3.3 * ratio;
        ctx.shadowColor = "rgba(0,0,0,0.17)";
        ctx.stroke(cloudPath);
        ctx.restore();

        //draw the clipping path
        ctx.setTransform(ratio, 0, 0, ratio, x, y);
        ctx.globalCompositeOperation = "destination-in";
        ctx.fill(cloudPath);

        //draw the fill
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = deepGradient(ctx);
        ctx.fill(cloudPath);

        //clean up:
        ctx.globalCompositeOperation = "source-over";
    }

    function drawWhiteCloud(ctx, x, y, designWidth, designHeight, path) {
        const { width, height, ratio } = fitCloudInto(
            designWidth,
            designHeight
        );
        ctx.save();
        ctx.setTransform(ratio, 0, 0, ratio, x, y);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#FFFFFF";
        whiteCloudShadow(ctx, ratio);
        ctx.fillStyle = whiteGradient(ctx);
        ctx.fill(cloudPath);
        ctx.restore();

        const { logoX, logoY, logoRatio } = logoPosition(x, y, width, height);
        drawLogo(ctx, logoX, logoY, logoRatio, path);
    }

    function logoPosition(parentX, parentY, parentWidth, parentHeight) {
        // the logos svg size
        const logoWidth = 200;
        const logoHeight = 100;

        const { width, height, ratio } = fitInto(
            logoWidth,
            logoHeight,
            parentWidth,
            parentHeight
        );
        return {
            logoX: parentX + (parentWidth - width) / 2,
            logoY: parentY + (parentHeight - height * 0.92) / 2, //slightly adjust centerY
            logoRatio: ratio
        };
    }

    function drawLogo(ctx, x, y, ratio, path) {
        ctx.setTransform(ratio, 0, 0, ratio, x, y);
        ctx.fillStyle = "#515151";
        ctx.fill(path, "evenodd");
    }

    function loadPath(path) {
        const cachedPath = paths.get(path);
        if (cachedPath) {
            return Promise.resolve(cachedPath);
        }
        return fetch(`/images/${path}.data`)
            .then(response => {
                if (response.status < 400) {
                    return response.text();
                }
            })
            .then(data => {
                const path2d = new Path2D(data);
                paths.set(path, path2d);
                return path2d;
            })
            .catch(err => {
                console.error({ err });
            });
    }

    function whiteGradient(ctx, width = 100, height = 72) {
        const gradient = ctx.createLinearGradient(0, 0, width, height);
        gradient.addColorStop(0, "#FFFFFF");
        gradient.addColorStop(1, "#E4E2E3");
        return gradient;
    }

    function deepGradient(ctx, width = 100, height = 72) {
        const gradient = ctx.createLinearGradient(0, 0, width, height);
        gradient.addColorStop(0, "#5C93AD");
        gradient.addColorStop(1, "#538399");
        return gradient;
    }

    function whiteCloudShadow(ctx, ratio) {
        ctx.shadowOffsetX = ctx.shadowOffsetY = 4 * ratio;
        ctx.shadowBlur = 3.3 * ratio;
        ctx.shadowColor = "rgba(0,0,0,0.17)";
    }

    function fitCloudInto(maxWidth, maxHeight) {
        return fitInto(100, 71.8, maxWidth, maxHeight);
    }
    function fillInCloudSize(maxWidth, maxHeight) {
        return fillIn(100, 71.8, maxWidth, maxHeight);
    }

    function fitInto(width, height, maxWidth, maxHeight) {
        if (height == 0) {
            return { width, height, ratio: 1 };
        }
        const ratio = width / height;
        const maxRatio = maxWidth / maxHeight;
        if (maxRatio <= ratio) {
            //subject is horizontal
            return {
                width: maxWidth,
                height: maxWidth / ratio,
                ratio: maxWidth / width
            };
        } else {
            return {
                width: maxHeight * ratio,
                height: maxHeight,
                ratio: maxHeight / height
            };
        }
    }

    function fillIn(width, height, maxWidth, maxHeight) {
        if (height == 0 || maxHeight == 0) {
            return { width, height, ratio: 1 };
        }
        const ratio = width / height;
        const maxRatio = maxWidth / maxHeight;
        if (maxRatio <= ratio) {
            //subject is horizontal
            return {
                width: maxHeight * ratio,
                height: maxHeight,
                ratio: maxHeight / height
            };
        } else {
            return {
                width: maxWidth,
                height: maxWidth / ratio,
                ratio: maxWidth / width
            };
        }
    }
})();
